<template>
  <div>
    <vx-card>
      <copy-right></copy-right>
    </vx-card>
  </div>
</template>
<script>
import copyRight from '../../views/apps/emrcomponents/Copyright'
export default {
  components: {
    copyRight,
  },
}
</script>
